<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
      

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Message Title(En):</span>
              <span class="text--secondary">{{ itemData.title_en }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Message Title(Ar):</span>
              <span class="text--secondary">{{ itemData.title_ar }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Message Text(En):</span>
              <span class="text-capitalize">{{ itemData.message_en ? itemData.message_en.substring(0, 40) +
                  '...' : itemData.message_en
              }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Message Text(Ar):</span>
              <span class="text-capitalize">{{ itemData.message_ar ? itemData.message_ar.substring(0, 40) +
                  '...' : itemData.message_ar
              }}</span>
            </v-list-item>

            <v-card-actions class="justify-center mt-3">
              <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen">
                Edit
              </v-btn>
              <v-btn color="error" outlined @click="removeItem(itemData.id)">
                Delete
              </v-btn>
            </v-card-actions>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <message-bio-edit :is-bio-dialog-open.sync="isBioDialogOpen" :item-data="itemData" @refresh="updateValue($event)">
      </message-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import MessageBioEdit from './MessageBioEdit.vue'

export default {
  components: {
    MessageBioEdit,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('refresh', value)
    },
    removeItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      })
        .then(result => {
          if (result.isConfirmed) {
            store
              .dispatch('app-messages/deleteMessage', id)
              .then(() => {
                Swal.fire('Deleted!', 'entry has been deleted.', 'success')
                this.$router.push({ path: '/apps/messages/list' })
              })
              .catch(error => {
                Swal.fire({
                  icon: 'error',
                  title: `err`,
                  text: error,
                })
              })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  setup() {
    // const { resolveCompanyStatusVariant, resolveCompanyRoleVariant } = useCompaniesList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
