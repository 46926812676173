<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="1250px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="item-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5">
        Edit Message Information
      </v-card-title>
      <v-card-text class="text-center mt-n2">
        Updating Message details will receive a privacy audit.
      </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field v-model="messageDataLocal.title_en" :rules="[validators.customeRequired]" outlined dense
                label="Title En"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field v-model="messageDataLocal.title_ar" :rules="[validators.customeRequired]" outlined dense
                label="Title Ar"></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea v-model="messageDataLocal.message_en" outlined label="Message (En)" :rules="[validators.customeRequired]" placeholder="Message (En)">
              </v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea v-model="messageDataLocal.message_ar" outlined label="Message (Ar)" :rules="[validators.customeRequired]" placeholder="Message (Ar)">
              </v-textarea>
            </v-col>


          </v-row>


          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit">
                submit
              </v-btn>

              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from '@/store'
import { customeRequired, emailValidator, imageValidator, integerValidator, required } from '@core/utils/validation'
import { mdiDelete, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const modal = ref(false)
    const menu2 = ref(false)
    const isBillingAddress = ref(true)

    const messageDataLocal = ref({})

    messageDataLocal.value = props.itemData

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetItemData = () => {
      messageDataLocal.value = JSON.parse(JSON.stringify(messageDataLocal))
      resetForm()
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        try {
          await store.dispatch('app-messages/editMessage', messageDataLocal.value).then(response => {})

          Swal.fire('Updated!', 'data has been updated successfully.', 'success')
          emit('refresh', messageDataLocal.value)
          emit('update:is-bio-dialog-open', false)
        } catch (error) {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `${error.message}`,
          })
          console.error(error)
        }
      } else {
        validate()
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        messageDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
      },
    )

    return {
      messageDataLocal,
      form,
      valid,
      resetItemData,
      validate,
      onSubmit,
      modal,
      menu2,
      // validation
      validators: {
        required,
        emailValidator,
        imageValidator,
        integerValidator,
        customeRequired,
      },
      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
